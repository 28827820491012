var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "sidebar" }, [
        _c("span", { on: { click: _vm.openGuidedTour } }, [_vm._v("导览")]),
      ]),
      _c(
        "a-drawer",
        {
          attrs: {
            title: "创建险金体系导览",
            placement: "right",
            closable: false,
            width: "30%",
            visible: _vm.guidedTour,
          },
          on: { close: _vm.onClose },
        },
        [
          _c("div", { staticClass: "guidedTour" }, [
            _c("p", [
              _vm._v("1、此地图中未添加"),
              _c("span", { staticClass: "font-color" }, [_vm._v("*")]),
              _vm._v("的应根据企业缴纳险金情况配置社保、医保、公积金方案"),
            ]),
            _c("p", [
              _vm._v("2、此地图中添加"),
              _c("span", { staticClass: "font-color" }, [_vm._v("*")]),
              _vm._v("的必须设置"),
            ]),
            _c("div", { staticClass: "guidedTour-content" }, [
              _c("div", { staticClass: "principal" }, [_vm._v("START")]),
              _c("hr"),
              _c(
                "div",
                { staticClass: "branch" },
                [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.onClick("insuredSubjects")
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "check-circle" } }),
                      _vm._v(" 设置参保主体"),
                      _c("span", { staticClass: "font-color" }, [_vm._v("*")]),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.onClick("socialSecurityScheme")
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "check-circle" } }),
                      _vm._v(" 设置社保方案 "),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.onClick("healthCareScheme")
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "check-circle" } }),
                      _vm._v(" 设置医保方案 "),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.onClick("providentFundScheme")
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "check-circle" } }),
                      _vm._v(" 设置公积金方案 "),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.onClick("messageReminders")
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "check-circle" } }),
                      _vm._v(" 设置消息提醒 "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "deawer-buttom" },
            [
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.onClose } },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }